html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
  // border: solid 20px red !important;
}

body .capitalize-text {
  text-transform: capitalize;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.modal-historial {
  width: "1200px" !important;
}

.space-items-between {
  display: flex !important;
  justify-content: space-between !important;
}

.position-end {
  display: flex;
  justify-content: end;
  align-items: end;
}

.notification {
  position: relative;
  padding-left: 20px;
  /* Ajusta el espacio para el círculo */
  margin-bottom: 10px;
}

.notification::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  /* Tamaño del círculo */
  height: 10px;
  /* Tamaño del círculo */
  border-radius: 50%;
}

.notification.new::before {
  background-color: #3498db;
  /* Color del círculo para nuevas notificaciones */
}

.notification.read::before {
  background-color: #e5e7e9;
  // background-color: #00ff00;
  /* Color del círculo para notificaciones leídas */
}

.large-text {
  white-space: nowrap;
  /* Evita que el texto se divida en múltiples líneas */
  overflow: hidden;
  /* Oculta el contenido que se desborda */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos al final del texto truncado */
  width: 200px;
  /* Ajusta el ancho según tu diseño */
}

.accordion {
  // border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 4px;
}

.accordion-header {
  // background-color: #f2f2f2;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.accordion-content {
  padding: 10px;
}

.texto-contenedor {
  position: relative;
  max-height: 200px;
  /* Altura máxima del texto corto */
  overflow: hidden;
}

.texto {
  margin: 0;
}

.oculto {
  display: none;
}

.ver-mas-btn {
  // display: block;
  margin-top: 10px;
  // background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.center-data {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
  height: 100%;
}

.center-data-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Para alinear al inicio horizontalmente */
  justify-content: flex-end;
  /* Para alinear al final verticalmente */
  height: 100%;
}

.center-btn {
  display: flex;
  align-items: center;
}

.card-border {
  width: 300px;
  /* Ancho de la tarjeta, ajusta según tus necesidades */
  height: 200px;
  /* Altura de la tarjeta, ajusta según tus necesidades */
  background-color: #fff;
  /* Color de fondo de la tarjeta */
  border-radius: 10px;
  /* Radio de borde para hacer la tarjeta redondeada */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Propiedad de sombra */
  /* Otros estilos de la tarjeta, como margen, relleno y más */
}

.fs-010 {
  font-size: 10px !important;
}

.fs-012 {
  font-size: 12px;
}

.fs-015 {
  font-size: 15px;
}

.fs-020 {
  font-size: 20px;
}

.fs-025 {
  font-size: 25px;
}

.fs-030 {
  font-size: 30px;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-white {
  background-color: #fff;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-020 {
  margin-top: 20px;
}

.mr-05 {
  margin-right: 5px;
}

.br-15 {
  border-radius: 15px;
}

.ioiQXV {
  background-color: #fdfefe !important;
}

.elements-end {
  display: flex !important;
  justify-content: end !important;
  align-items: end !important;
}

.sp-bt-c {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.card-box-shadow {
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: 10px 0 5px -2px #888;
}

.padding-card-board {
  padding: 0px 1rem 0px 2rem !important;
  // padding: 0px 2.25rem 1.5rem 2.25rem !important;
}

.padding-heard-board {
  padding: 1.5rem 2.25rem 1.5rem 2.25rem !important;
}

.bt {
  border-top: 5px solid #5dade2;
}

.ewFytm {
  background-color: #f7f9f9 !important;
}

.borders {
  border-bottom: solid 2px #d5dbdb !important;
  border-right: solid 2px #d5dbdb !important;
}

.borders-card-board {
  border-bottom: solid 2px #d5dbdb !important;
  border-right: solid 2px #d5dbdb !important;
  border-top: solid 1px #d5dbdb !important;
  border-left: solid 1px #d5dbdb !important;
}

.w-0310 {
  width: 310px;
}

.input-focus {
  border-color: rgba(101, 187, 245, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(119, 168, 240, 0.6);
  outline: 0 none;
}

.ioiQXV {
  height: auto !important;
}

.text-task-01 {
  color: #edcc59 !important;
}

.text-task-02 {
  color: #c87dc7 !important;
}

.text-task-03 {
  color: #f48f38 !important;
}

.text-task-04 {
  color: #9ccf59 !important;
}

.text-task-05 {
  color: #024faf !important;
}

.text-task-06 {
  color: #f15a5a !important;
}

.text-task-07 {
  color: #ffbd59 !important;
}

.text-task-08 {
  color: #02a858 !important;
}

.text-task-09 {
  color: #4876b9 !important;
}

.text-task-10 {
  color: #08b0cd !important;
}

.text-black {
  color: #000000 !important;
}

.margin-left-05 {
  margin-left: 5px !important;
}

.margin-left-020 {
  margin-left: 20px !important;
}

.height-0012px {
  height: 12px !important;
}

.wh-0100 {
  width: 100% !important;
}

.border-btn {
  border: solid 1px #d6dbdf !important;
}

.padd-left {
  padding-left: 0px !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* Ajusta el valor de opacidad aquí */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-name-form {
  width: 100%;
  padding: 10px;
  font-size: 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.padding-top-00 {
  padding-top: 0px;
}

.tooltip-soft .tooltiptext {
  visibility: hidden;
  width: max-content;
  // min-width: 150px;
  background-color: #fff;
  box-shadow: 5px 5px 6px -3px rgba(0, 0, 0, 0.19);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 80%;
  margin-left: -60px;
  // margin-top: 60px;
}

.tooltip-soft:hover .tooltiptext {
  visibility: visible;
}

.bg-list-01 {
  background-color: #edcc59 !important;
}

.bg-list-02 {
  background-color: #c87dc7 !important;
}

.bg-list-03 {
  background-color: #f48f38 !important;
}

.bg-list-04 {
  background-color: #9ccf59 !important;
}

.bg-list-05 {
  background-color: #024faf !important;
}

.bg-list-06 {
  background-color: #f15a5a !important;
}

.bg-list-07 {
  background-color: #ffbd59 !important;
}

.bg-list-08 {
  background-color: #02a858 !important;
}

.bg-list-09 {
  background-color: #4876b9 !important;
}

.bg-list-10 {
  background-color: #08b0cd !important;
}

@keyframes scrollAnimation {
  0% {
    scroll-behavior: auto;
  }

  100% {
    scroll-behavior: smooth;
  }
}

.input-scroll-animation {
  animation: scrollAnimation 0.5s ease-in-out;
}

.imagen {
  width: 100%;
  height: 100%;
  // transition: transform 0.3s ease;
  transition: all 0.3s ease-in-out;
  /* Efecto de transición suave */
}

.imagen:hover {
  transform: scale(1.2);
  // transform: scale(1.2); /* Ajusta el nivel de zoom según sea necesario */
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.break-word-text-large {
  overflow-wrap: break-word;
}

.lg-text {
  white-space: nowrap;
  /* Evita que el texto se divida en múltiples líneas */
  overflow: hidden;
  /* Oculta el contenido que se desborda */
  text-overflow: ellipsis;

  width: 100px;
}

.price-total {
  text-align: right;
}

.vertical-align-bottom {
  align-items: end;
}

@media screen and (max-width: 820px) {
  .price-total {
    text-align: left;
  }
}

.col {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .row-scroll.row > div {
    width: 50%;
    margin-top: 15px;
  }
  .mT-15 {
    margin-top: 15px;
  }
  .row-scroll.row > div input[type="text"] {
    width: 100% !important;
  }
  .row-scroll.row .pe-5 {
    padding-right: 0px !important;
  }
  .w50 {
    width: 50%;
  }
  .w100 {
    width: 100%;
  }
}
